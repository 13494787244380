import React from 'react'
import { Switch, Route } from 'react-router-dom'
import App from './App'
import AuthenticatedSso from './pages/authenticated'
// import NewUser from './pages/new-user'
// import RegisterNewUser from './pages/register-new-user'
// import InformPinCode from './pages/inform-pin-code'
// import Login from './pages/login'
import Tcs from './pages/tcs'

export default () => {
  return (
    <Switch>
      <Route path='/app' component={App} exact />
      <Route path='/app/*' component={App} />
      <Route path='/tcs' component={Tcs} />
      {/* <Route path='/login' component={Login} />
      <Route path='/login/*' component={Login} /> */}
      {/* <Route path='/inform-pin-code' component={InformPinCode} /> */}
      <Route path='/sso/authenticated/:hash' component={AuthenticatedSso} />
      {/* <Route path='/access-new-user/:hash' component={NewUser} /> */}
      {/* <Route path='/new-user/register-now' component={RegisterNewUser} /> */}
      <Route
        path='*'
        component={() => {
          window.location.href = process.env.REACT_APP_URL_PORTAL
        }}
      />
    </Switch>
  )
}
