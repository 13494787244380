import React, { useEffect, useReducer } from 'react'
import numeral from 'numeral'
import { RiCloseLine, RiErrorWarningLine } from 'react-icons/ri'
import { FcCheckmark } from 'react-icons/fc'

import Loading from '../../component/loading'

import getNumberPdf from '../../domain/document/get-number'
import findQuotationById from '../../domain/quotation/find-quotation-by-id'

import ModalComponent from '../../component/modal'

import reducer from './reducer'
import { ContentModal } from './styles'

const initialState = {
  list: []
}

const Modal = ({ open, handleClose, maxWidth, rowsSelecteds, ...res }) => {
  const [state, dispatchReducer] = useReducer(reducer, initialState)

  async function getDataDocuments(rows) {
    if (rows.length > 0) {
      let list = rows

      rows.forEach((row, idx) => {
        findQuotationById(row.id).then((response) => {
          let doc = response.data.body

          if (doc.file) doc.download = 1

          if (doc.file) forceDownload(response.data.body, idx)

          list[idx] = doc

          dispatchReducer({ type: 'UPDATE_QUOTATIONS', list })
        })
      })
    }
  }

  function forceDownload(doc) {
    const blob = new Blob([new Uint8Array(doc.file.Body.data).buffer], {
      type: 'application/pdf'
    })

    const numberPdf = getNumberPdf({
      type: doc.type,
      number: doc.number,
      assignmentNumber: doc.assignmentNumber,
      billingNumber: doc.billingNumber
    })

    const namePdf = `${doc.customerCode}_${numberPdf}.pdf`

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', namePdf)
    document.body.appendChild(link)
    link.click()
  }

  function renderStatus(doc, open) {
    if (open) {
      if (
        (doc.download === 0 || doc.download === undefined) &&
        doc.file !== null
      )
        return <Loading size='26px' />

      if (doc.file === null)
        return <RiErrorWarningLine size={24} title='Document not found!' />

      if (doc.download === 1 && doc.file !== null)
        return <FcCheckmark size={24} />
    }
  }

  useEffect(() => {
    if (open) {
      dispatchReducer({ type: 'UPDATE_QUOTATIONS', list: rowsSelecteds })
      getDataDocuments(rowsSelecteds)
    }

    if (open === false) {
      dispatchReducer({
        type: 'UPDATE_QUOTATIONS',
        list: []
      })
    }
  }, [rowsSelecteds, open])

  return (
    <ModalComponent open={open} handleClose={handleClose}>
      <ContentModal maxWidth={maxWidth}>
        <div className='header-modal'>
          <h3>Download dos PDF</h3>
          <span onClick={handleClose}>
            <RiCloseLine size={18} />
          </span>
        </div>

        <div className='modal-body'>
          <div className='row-doc-body'>
            <div className='number'>
              <strong>Invoice</strong>
            </div>
            <div className='total-amount'>
              <strong>Total</strong>
            </div>
            <div className='progressive-bars'>
              <strong>Status</strong>
            </div>
          </div>

          {state.list.length
            ? state.list.map((doc, idx) => (
                <div key={doc.id} className='row-doc-body'>
                  <div className='number'>
                    {doc.number ? doc.number : '...'}
                  </div>
                  <div className='total-amount'>
                    {doc.totalAmount
                      ? numeral(doc.totalAmount).format('0,0.00')
                      : '...'}
                  </div>
                  <div className='progressive-bars'>
                    {renderStatus(doc, open, idx)}
                  </div>
                </div>
              ))
            : null}
        </div>
      </ContentModal>
    </ModalComponent>
  )
}

export default Modal
