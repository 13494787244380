const config = {
  type: 'serial',
  theme: 'light',
  graphs: [
    {
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD - Expiration Date: [[expire_at]]</b></span>',
      fillAlphas: 1,
      fillColors: '#e1ede9',
      labelText: '[[value]] [[Currency]] - até [[expire_at]]',
      lineAlpha: 0.3,
      title: 'Credit Limit',
      type: 'column',
      valueField: 'CreditLimit',
      showHandOnHover: true
    },
    {
      valueAxis: 'v1',
      balloonText:
        '<b>[[title]]</b><br><span>[[category]]: <b>[[value]] USD</b></span>',
      fillAlphas: 1,
      labelText: '[[value]] [[Currency]] ([[percentage]]%)',
      lineAlpha: 0.3,
      title: 'Used Limit',
      type: 'column',
      valueField: 'SumOpens',
      showHandOnHover: true
    }
  ],
  categoryField: 'name',
  rotate: true,
  categoryAxis: {
    autoRotateAngle: 43.2,
    autoRotateCount: 0,
    gridPosition: 'start',
    axisAlpha: 0,
    gridAlpha: 0
  },
  valueAxes: [{ stackType: 'none', axisAlpha: 0.3, gridAlpha: 0 }],
  prefixesOfBigNumbers: [
    {
      number: 1e3,
      prefix: 'k'
    },
    {
      number: 1e6,
      prefix: 'm'
    },
    {
      number: 1e9,
      prefix: 'b'
    },
    {
      number: 1e12,
      prefix: 't'
    }
  ],
  legend: {
    useGraphSettings: true,
    position: 'bottom'
  },
  usePrefixes: true,
  chartCursor: {
    fullWidth: true,
    cursorAlpha: 0.1,
    categoryBalloonColor: '#100980',
    balloonPointerOrientation: 'horizontal'
  },
  startDuration: 2
}

export { config }
