import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from '../component/breadcrumbs'
import DashboardFilter from '../container/customer-dashboard-fixed-filter'
import CreditLimitChart from '../charts/credit-limit'

import CardAnalytical from '../container/customer-dashboard-cards-analytical'
import CustomerPaymentInfo from '../charts/customer-payment-info'
import DisputePerStatus from '../charts/dispute-per-status'

import logoConfidential from '../assets/images/confidential.png'

export default () => {
  const { t } = useTranslation()

  return (
    <div>
      <div
        className='i-container'
        style={{ display: 'flex', marginTop: 32, alignItems: 'center' }}>
        <div>
          <Breadcrumbs />
        </div>
        <div>
          <img
            src={logoConfidential}
            alt='Confidential'
            style={{ width: 100, marginLeft: 16 }}
          />
        </div>
      </div>

      <DashboardFilter />

      <div className='i-container'>
        <div className='i-ar'>
          <CardAnalytical />
        </div>

        <div style={{ paddingTop: '40px' }}>
          <Grid container direction='row' spacing={3}>
            <Grid item sm={12} md={12} style={{ width: '100%' }}>
              <CreditLimitChart title={t('customer-credit-limit')} />
            </Grid>
            <Grid item sm={12} md={12} style={{ width: '100%' }}>
              <CustomerPaymentInfo
                title={t('customer-dashboard-payment-info')}
              />
            </Grid>

            <Grid item sm={12} style={{ width: '100%' }}>
              <DisputePerStatus title={'Dispute Information'} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
