import styled from 'styled-components'

export const ContainerFooterSub = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;

  .show-count-total {
    width: 50%;
  }

  .show-count-total-currency {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const ContainerFooterTop = styled.div`
  display: flex;
  width: 100%;

  .btn-show-more-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-mais-opcoes-right {
    justify-content: flex-end;
    text-align: right;
  }
`
