import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { findAggByCustomer } from '../../domain/document/'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AmChartSerial from '../amcharts-serial'
import IsEmptyChart from '../amcharts-is-empty'
import configChart from './configChart'
import { get, isEmpty } from 'lodash'

const getOverduePerCustomers = (state) => {
  return get(state, 'getOverduePerCustomers.overduePerCustomers', [])
}

const OverduePerCustomers = (props) => {
  const history = useHistory()

  const config = {
    graphs: configChart.graphs,
    rotate: true,
    categoryField: 'groupName',
    categoryAxis: configChart.categoryAxis,
    export: configChart.export,
    listeners: [
      {
        event: 'clickGraphItem',
        method: (event) => {
          configChart.method(event, history)
        }
      }
    ]
  }

  const { t } = useTranslation()
  const reduxDataProvider = useSelector(getOverduePerCustomers)
  const [stateDataProvider, setStateDataProvider] = useState(reduxDataProvider)
  const [state, setState] = useState(false)
  const [configGraph, setConfigGraph] = useState(config)

  const loading = () =>
    findAggByCustomer().then((dataProvider) =>
      setStateDataProvider(dataProvider.data.body)
    )

  useEffect(() => {
    loading()
    setState(true)
  }, [state])

  useEffect(() => {
    setStateDataProvider(reduxDataProvider)
  }, [reduxDataProvider])

  useEffect(() => {
    const newConfigGraph = configGraph
    newConfigGraph.graphs[0].title = t('overdue')
    newConfigGraph.graphs[1].title = t('credit')
    newConfigGraph.graphs[2].title = t('dispute')
    newConfigGraph.graphs[3].title = t('notOverdue')
    setConfigGraph(newConfigGraph)
  }, [t('overdue')])

  return !isEmpty(stateDataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>

        <AmChartSerial
          height={'1397px'}
          dataConfig={configGraph}
          dataProvider={stateDataProvider}
        />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} overduePerCustomers={true} />
  )
}

export default OverduePerCustomers
