import axios from 'axios'
import { getBearerAuthorization } from '../auth'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/notifications`

const sendNotification = async ({ emails, notification, code }) => {
  return await axios.post(
    `${url}`,
    { emails, notification, code },
    {
      headers: getBearerAuthorization()
    }
  )
}

export { sendNotification }
