import { GET_SEGMENTS } from './actions'
import { set, pipe } from 'lodash/fp'

const INITIAL_STATE = {
  segments: [],
  segmentSelected: { value: '', label: '' }
}

const businesUnits = {
  10: {
    description: '10 - Commercial'
  },
  20: {
    description: '20 - Defense'
  },
  30: {
    description: '30 - Executive'
  },
  40: {
    description: '40 - Industry'
  },
  50: {
    description: '50 - Agricultural'
  },
  90: {
    description: '90 - Intercompany'
  },
  232: {
    description: 'DP - Defense Program'
  }
}

const setSegmentsValues = (segments) =>
  segments
    .filter((item) => item.segment !== '70')
    .map((item) =>
      pipe(set('description')(businesUnits[item.segment].description))(item)
    )

export const getSegments = (state = INITIAL_STATE, action) => {
  if (action.type === GET_SEGMENTS) {
    return {
      ...state,
      segments: [...setSegmentsValues(action.segments.data.body)]
    }
  }
  return state
}
