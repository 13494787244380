import React from 'react'
import Check from '@material-ui/icons/Check'
import AccessTime from '@material-ui/icons/AccessTime'

export default (row) =>
  row.transactionNumber ? (
    <Check fontSize='large' style={{ color: '#40a766' }} />
  ) : (
    <AccessTime fontSize='large' style={{ color: '#ffa000' }} />
  )
