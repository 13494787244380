import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'
import { set, pipe } from 'lodash/fp'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { findAggBySegment } from '../../domain/document/'
import AmChartsSerial from '../amcharts-serial'
import IsEmptyChart from '../amcharts-is-empty'
import { config } from './config.js'

const getChartSegments = (state) => get(state, 'getChartSegments.segments', [])

const ArSegment = (props) => {
  const { t } = useTranslation()

  const businesUnits = {
    10: {
      description: t('busines-units-10')
    },
    20: {
      description: t('busines-units-20')
    },
    30: {
      description: t('busines-units-30')
    },
    40: {
      description: t('busines-units-40')
    },
    50: {
      description: t('busines-units-50')
    },
    90: {
      description: t('busines-units-90')
    },
    232: {
      description: t('busines-units-232')
    }
  }

  const reduxDataProvider = useSelector(getChartSegments)
  const [stateDataProvider, setStateDataProvider] = useState(reduxDataProvider)
  const [state, setState] = useState(false)
  const [configGraph, setConfigGraph] = useState(config)

  const setSegmentsValues = (segments) =>
    segments
      .filter((item) => item.segment !== '70')
      .map((item) =>
        pipe(set('segment')(businesUnits[item.segment].description))(item)
      )

  const loading = () =>
    findAggBySegment().then((dataProvider) =>
      setStateDataProvider(setSegmentsValues(dataProvider.data.body))
    )

  useEffect(() => {
    loading()
    setState(true)
  }, [state])

  useEffect(() => {
    setStateDataProvider(setSegmentsValues(reduxDataProvider))
  }, [reduxDataProvider])

  useEffect(() => {
    const newConfigGraph = configGraph
    newConfigGraph.graphs[0].title = t('overdue')
    newConfigGraph.graphs[1].title = t('credit')
    newConfigGraph.graphs[2].title = t('dispute')
    newConfigGraph.graphs[3].title = t('notOverdue')
    setConfigGraph(newConfigGraph)
  }, [t('overdue')])

  return !isEmpty(stateDataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>

        <AmChartsSerial
          dataConfig={configGraph}
          dataProvider={stateDataProvider}
        />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} />
  )
}

export default ArSegment
