import { useTranslation } from 'react-i18next'

export default ({ EPAYMENTS_UI, user }) => {
  const { t } = useTranslation()

  return [
    {
      id: 'dashboard',
      name: t('menu-dashboard'),
      route: '/app/dashboard',
      target: '',
      external: false,
      icon: 'dashboard',
      subMenu: false,
      roles: [
        'OLP_ADMIN',
        'OLP_MANAGER',
        'OLP_BASIC_USER',
        'OLP_FINANCIER',
        'OLP_SUPPORT',
        'OLP_ADMIN_SJK'
      ]
    },
    {
      id: 'customer-dashboard',
      name: user.profile.includes('OLP_CUSTOMER')
        ? t('menu-dashboard')
        : t('customer-menu-dashboard'),
      route: '/app/customer-dashboard',
      target: '',
      external: false,
      icon: 'dashboard',
      subMenu: false,
      roles: [
        'OLP_CUSTOMER',
        'OLP_ADMIN',
        'OLP_MANAGER',
        'OLP_BASIC_USER',
        'OLP_FINANCIER',
        'OLP_SUPPORT',
        'OLP_ADMIN_SJK'
      ]
    },
    {
      id: 'invoices',
      name: t('menu-invoices'),
      route: '/app/invoices',
      target: '',
      external: false,
      icon: 'description',
      subMenu: false,
      roles: []
    },
    {
      id: 'quotations',
      name: t('menu-quotations'),
      route: '/app/quotations',
      target: '',
      external: false,
      icon: 'currency_exchange',
      subMenu: false,
      roles: []
    },
    {
      id: 'reversal',
      name: t('menu-reversal'),
      route: `${EPAYMENTS_UI}/reversal`,
      target: '_blank',
      external: true,
      icon: 'swap_horiz',
      subMenu: false,
      roles: ['OLP_FINANCIER', 'OLP_SUPPORT']
    },
    {
      id: 'report',
      name: t('menu-report'),
      route: '/app/report',
      target: '',
      external: false,
      icon: 'notes',
      subMenu: false,
      roles: []
    },
    {
      id: 'interest_free_invoices',
      name: t('menu-interest-free'),
      route: '/app/interest-free-invoices',
      target: '',
      external: false,
      icon: 'block',
      subMenu: false,
      roles: ['OLP_SUPPORT', 'OLP_FINANCIER']
    },
    {
      id: 'customer_setup',
      name: t('menu-customer-setup'),
      route: '/app/customer-setup',
      target: '',
      external: false,
      icon: 'supervisor_account',
      subMenu: false,
      roles: ['OLP_MANAGER', 'OLP_CUSTOMER_SETUP', 'OLP_SUPPORT']
    },
    {
      id: 'account_document',
      name: t('menu-account-documents'),
      route: '/app/accounting',
      target: '',
      external: false,
      icon: 'adjust',
      subMenu: false,
      roles: ['OLP_CUSTOMER_SETUP', 'OLP_SUPPORT', 'OLP_FINANCIER']
    },
    {
      id: 'doc_sap_web',
      name: t('menu-account-doc-sap-web'),
      route: '/app/document-sap-web',
      target: '',
      external: false,
      icon: 'description',
      subMenu: false,
      roles: ['OLP_SUPPORT']
    },
    {
      id: 'customer_support',
      name: t('menu-customer-support'),
      route: '/app/customer-support',
      target: '',
      external: false,
      icon: 'help',
      subMenu: false,
      roles: []
    },
    {
      id: 'dispute_information',
      name: t('menu-dispute-information'),
      route: '/app/dispute-information',
      target: '',
      external: false,
      icon: 'gavel',
      subMenu: false,
      roles: []
    }
  ]
}
