import { GET_COMPANIES_QUOTATIONS } from './actions'

const INITIAL_STATE = {
  companies: []
}

export const getCompaniesQuotations = (state = INITIAL_STATE, action) => {
  if (action.type === GET_COMPANIES_QUOTATIONS)
    return { ...state, companies: [...action.companies] }
  return state
}
