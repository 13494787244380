import React from 'react'
import { useTranslation } from 'react-i18next'

export default (numSelected, rowCount) => {
  const { t } = useTranslation()

  return numSelected ? (
    <span style={{ width: '100%' }}>
      <span className='i-label-count-invoices i-label-count-invoices-checked'>
        {numSelected}
      </span>
      {t('invoices-of')} {rowCount} {t('invoices-selected')}
    </span>
  ) : (
    <span>
      <span className='i-label-count-invoices'>{numSelected}</span>
      {t('invoices-of')} {rowCount} {t('invoices')}
    </span>
  )
}
