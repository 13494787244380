import React from 'react'
import Tooltip from '../../../component/tooltip'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'

export default (
  <Tooltip
    textTooltip='Core Return'
    color='#ffffff'
    position='bottom-end'
    backgroundColor='rgba(0,0,0,0.75)'>
    <AssignmentReturnIcon
      fontSize='large'
      style={{ color: '#100690', margin: '11px' }}
    />
  </Tooltip>
)
