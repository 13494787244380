import React, { Fragment } from 'react'
import { Select, MenuItem, InputLabel } from '@material-ui/core'

export default (props) => {
  return (
    <Fragment>
      <InputLabel id='dispute-reason-select-label'>{props.label}</InputLabel>
      <Select
        labelId='dispute-reason-select-label'
        id='dispute-reason-select'
        value={props.selectedValue}
        onChange={props.handleChange}
        style={{ width: props.size }}>
        {props.options.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
    </Fragment>
  )
}
