export default ({
  type,
  number,
  fiscalNumber,
  billingNumber,
  assignmentNumber
}) => {
  if (['DC', 'ZCOT', 'ZTCO'].includes(type)) return number

  if (billingNumber) return billingNumber

  return fiscalNumber ? fiscalNumber : assignmentNumber
}
