import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import QuotationFilter from '../container/quotation-fixed-filter'
import { Tab, TabPanel } from '../container/quotation-tab'
import CompanyQuotations from '../container/company-quotations'
import SearchFilter from '../container/input-search-quotations'

import Alert from '../component/alert/'
import Loading from '../component/loading'
import Breadcrumbs from '../component/breadcrumbs'

const Quotations = () => {
  const { t } = useTranslation()
  const { selects, status, loadingStatus } = useSelector((state) =>
    get(state, 'getFiltersQuotations', [])
  )

  const [showAlert, setShowAlert] = useState(true)
  const [inputSearch, setInputSearch] = useState('')

  const AlertMemo = useMemo(() => {
    return (
      <Alert
        showAlert={showAlert}
        onAlertClick={(status) => {
          setShowAlert(status)
        }}
        type='warning'
        closable={true}
        title={t('alert-invoices-title')}
        message={t('alert-quotation-menssage')}
      />
    )
  }, [showAlert, setShowAlert])

  const QuotationFilterMemo = useMemo(
    () => <QuotationFilter setInputSearch={setInputSearch} />,
    [setInputSearch]
  )
  const SearchFilterMemo = useMemo(
    () => (
      <SearchFilter inputSearch={inputSearch} setInputSearch={setInputSearch} />
    ),
    [inputSearch, setInputSearch]
  )
  const CompanyQuotationsMemo = useMemo(
    () => <CompanyQuotations selects={selects} status={status} />,
    [selects, status]
  )

  return (
    <div>
      <div className='i-container'>
        <Breadcrumbs title={t('breadcrumbs-title-quotations')} />
      </div>

      {QuotationFilterMemo}

      <div className='i-container'>{AlertMemo}</div>

      {SearchFilterMemo}

      <Tab activeChange={true}>
        <TabPanel label={t('tabe-label-open')} index={0}>
          <div className='i-container'>
            {loadingStatus ? <Loading /> : CompanyQuotationsMemo}
          </div>
        </TabPanel>

        <TabPanel label={t('tabe-label-closed')} index={1}>
          <div className='i-container'>
            {loadingStatus ? <Loading /> : CompanyQuotationsMemo}
          </div>
        </TabPanel>
      </Tab>
    </div>
  )
}

export default Quotations
