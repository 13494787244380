import { difference } from 'lodash/fp'

const addRow =
  (rowsSelecteds = []) =>
  (row) =>
    difference(rowsSelecteds, [row]).concat(difference([row], rowsSelecteds))

const addAll =
  (rowsSelecteds = []) =>
  (status) =>
    status ? rowsSelecteds : []

export { addRow, addAll }
