import styled from 'styled-components'

export const ContentModal = styled.div`
  width: ${(props) => props.maxWidth + 'px'};
  background-color: #fff;
  border-radius: 10px;

  .header-modal {
    display: flex;
    width: 100%;
    padding: 16px;
    background-color: #448ad1;
    border-radius: 10px 10px 0px 0px;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: normal;
    }

    span {
      cursor: pointer;
    }

    span svg {
      color: #fff;
    }
  }

  .modal-body {
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    padding: 16px 0px;

    .row-doc-body {
      display: flex;
      padding: 8px 16px;
      border-top: 1px solid #ececec;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        border-top: 0px;
      }
    }
  }
`
