import axios from 'axios'
import { isEmpty } from 'lodash'

const getToken = () => localStorage.getItem('token')
const getBearerAuthorization = () => ({ Authorization: `Bearer ${getToken()}` })
const isNotAuthenticated = () => isEmpty(getToken())
const isAuthenticated = () => !isNotAuthenticated()

/**
 * get user authenticated api
 * @param {String} hash
 */
const getAuthenticatedUser = (hash) =>
  axios.get(`${process.env.REACT_APP_INVOICES_API}/sso/authenticated/${hash}`, {
    headers: getBearerAuthorization()
  })

const getRevalidateToken = () =>
  axios.post(
    `${process.env.REACT_APP_INVOICES_API}/sso/revalidate`,
    { token: getToken() },
    {
      headers: getBearerAuthorization()
    }
  )

export {
  getToken,
  isAuthenticated,
  isNotAuthenticated,
  getBearerAuthorization,
  getAuthenticatedUser,
  getRevalidateToken
}
