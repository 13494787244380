import React from 'react'
import TableCell from '../../../component/table/table-cell'
import showClearingNumberIcon from './show-clearing-number-icon'

export default (row, isClosed) => {
  if (isClosed)
    return (
      <TableCell align='left' className='clearing-number-icon'>
        {showClearingNumberIcon(row)}
      </TableCell>
    )
}
