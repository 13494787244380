import React from 'react'
import TableCell from '../../../component/table/table-cell'
import showClearingNumberIcon from './show-clearing-number-icon'
import Tooltip from '../../../component/tooltip'

export default (row, isClosed, t) => {
  if (isClosed)
    return (
      <>
        {row.transactionNumber &&
        row.transactionNumber.slice(0, 3) === 'Pay' &&
        !row.clearingNumber ? (
          row.voucher ? (
            <Tooltip
              textTooltip={t('wire-transfer-waiting-approval')}
              color='#ffffff'
              position='left'
              backgroundColor='rgba(0,0,0,0.75)'
              transfer={true}>
              <TableCell align='left' className='clearing-number-icon'>
                {showClearingNumberIcon(row)}
              </TableCell>
            </Tooltip>
          ) : (
            <Tooltip
              textTooltip={t('wire-transfer-waiting-receipt')}
              color='#ffffff'
              position='left'
              backgroundColor='rgba(0,0,0,0.75)'
              transfer={true}>
              <TableCell align='left' className='clearing-number-icon'>
                {showClearingNumberIcon(row)}
              </TableCell>
            </Tooltip>
          )
        ) : (
          <TableCell align='left' className='clearing-number-icon'>
            {showClearingNumberIcon(row)}
          </TableCell>
        )}
      </>
    )
}
