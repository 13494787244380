import { getBearerAuthorization } from '../../auth'
import { stringify } from 'query-string'
import { pick, get } from 'lodash'
import { pipe, set } from 'lodash/fp'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/companies`

const SEARCH_FIELDS = [
  'customer',
  'customerGroup',
  'accountManager',
  'leader',
  'criteria',
  'customerType',
  'salesOrganization',
  'dispute',
  'startDate'
]

const _composeFilter = ({ selects = [] }) =>
  selects
    ? selects.map((filter) =>
        pipe(set(get(filter, 'name'))(get(filter, 'selected.value')))({})
      )
    : {}

const list = async (query = {}) => {
  try {
    const { status } = query
    query = Object.assign(
      {},
      { status },
      pick(query, SEARCH_FIELDS),
      ..._composeFilter(query)
    )
    return await axios.get(`${url}?${stringify(query)}`, {
      headers: getBearerAuthorization()
    })
  } catch (error) {
    console.log(error)
  }
}

const listCompaniesQuotations = async (query = {}) => {
  try {
    const { status } = query
    query = Object.assign(
      {},
      { status },
      pick(query, SEARCH_FIELDS),
      ..._composeFilter(query)
    )
    return await axios.get(`${url}/quotation?${stringify(query)}`, {
      headers: getBearerAuthorization()
    })
  } catch (error) {
    console.log(error)
  }
}

const listDisputeCompanies = async (query = {}) => {
  try {
    const { status } = query
    query = Object.assign(
      {},
      { status },
      pick(query, SEARCH_FIELDS),
      ..._composeFilter(query)
    )
    return await axios.get(`${url}/company-disputes?${stringify(query)}`, {
      headers: getBearerAuthorization()
    })
  } catch (error) {
    console.log(error)
  }
}

export { list, listCompaniesQuotations, listDisputeCompanies }
