import { stringify } from 'query-string'
import { pick, get } from 'lodash'
import { pipe, set } from 'lodash/fp'
import { getBearerAuthorization } from '../auth'
import axios from 'axios'

const invoicesApi = process.env.REACT_APP_INVOICES_API
const url = `${invoicesApi}/v1/quotations`
const SEARCH_FIELDS = [
  'customer',
  'customerGroup',
  'accountManager',
  'leader',
  'criteria',
  'page',
  'order'
]

const _composeFilter = ({ selects = [] }) =>
  selects
    ? selects.map((filter) =>
        ['customerQuotation'].includes(get(filter, 'name'))
          ? pipe(set('customer')(get(filter, 'selected.value')))({})
          : {}
      )
    : {}

export default async (query = {}) => {
  const { salesOrganization, currency, status, page = 0 } = query
  query = Object.assign(
    {},
    { status, pageSize: 50, page },
    pick(query, SEARCH_FIELDS),
    ..._composeFilter(query)
  )
  return await axios.get(
    `${url}/${salesOrganization}/${currency}?${stringify(query)}`,
    {
      headers: getBearerAuthorization()
    }
  )
}
