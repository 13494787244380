export default {
  "usePrefixes": true,
	"prefixesOfBigNumbers": [{
		"number": 1e+3,
		"prefix": "k"
	},
	{	
		"number": 1e+6,
		"prefix": "m"
	},
	{
		"number": 1e+9,
		"prefix": "b"
	},
	{
		"number": 1e+12,
		"prefix": "t"
	}],
	"valueAxes": [{
		"stackType": "regular",
		"axisAlpha": 0.3,
		"gridAlpha": 0
	}],
  "type": "serial",
  "theme": "light",
  "startDuration": 2,
  "legend": {
    "align": "center",
    "useGraphSettings": true
  },
  "chartCursor": {
    "fullWidth": true,
    "cursorAlpha": 0.1,
    "categoryBalloonColor": "#100980",
    "balloonPointerOrientation": "horizontal"
  },
  "categoryAxis": {
		"autoRotateAngle": 43.2,
		"autoRotateCount": 0,
		"gridPosition": "start",
		"axisAlpha": 0,
		"gridAlpha": 0
	}
}