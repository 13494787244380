import getSorted from './get-sorted'
import handleClick from './handler-click'
import getSelectAll from './select-all'
import { addRow, addAll } from './add-row'

export default (state, action) => {
  if (action.type === 'sort')
    return {
      ...state,
      ...getSorted(state.orderBy, state.order)(action.property)
    }
  if (action.type === 'selectAll')
    return {
      ...state,
      statusCheckall: !state.statusCheckall,
      selected: getSelectAll(action.rows)(action.event),
      rowsSelecteds: addAll(action.rows)(!state.statusCheckall)
    }
  if (action.type === 'onClick')
    return { ...state, selected: handleClick(state.selected)(action.id) }
  if (action.type === 'addRow')
    return { ...state, rowsSelecteds: addRow(state.rowsSelecteds)(action.row) }
  if (action.type === 'changeRowsPerPage')
    return {
      ...state,
      page: action.page,
      rowsPerPage: +action.event.target.value
    }
  if (action.type === 'resetAll')
    return { ...state, selected: [], rowsSelecteds: [], statusCheckall: false }
  return state
}
