import { findAggByWeek, findAggByMonth } from '../../domain/document/'

const getButtonsApi = {
  weekly: async (query) => {
    var dataRetorn = []
    await findAggByWeek(query).then((dataProvider) => {
      dataRetorn = dataProvider.data.body
    })
    return dataRetorn
  },

  monthly: async (query) => {
    var dataRetorn = []

    await findAggByMonth(query).then((dataProvider) => {
      dataRetorn = dataProvider.data.body
    })
    return dataRetorn
  }
}

export default getButtonsApi
