import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { canPay, createCheckout } from '../../domain/quotation'
import Button from '../../component/button'
import Swal from 'sweetalert2'

const disableButton = ({
  hasGateway,
  hasAllowedCurrency,
  hasCustomerSelected,
  getTotalAmount
}) =>
  !canPay({
    hasGateway,
    hasAllowedCurrency,
    hasCustomerSelected,
    totalAmount: Number(getTotalAmount.totalAmount)
  })

const getAuth = (state) => get(state, 'auth.user')

export default (props) => {
  const { t } = useTranslation()
  const user = useSelector(getAuth)
  const [disableStatus, setDisableStatus] = useState(true)

  const handlePayNow = async (e) => {
    setDisableStatus(true)
    e.preventDefault()
    e.stopPropagation()

    try {
      const getCustomerCode =
        props.customer.value !== ''
          ? props.customer.value
          : props.customerGroup.value

      localStorage.clear()
      localStorage.setItem('@inputSearchQuotation', props.inputSearch)
      localStorage.setItem('@customerQuotation', JSON.stringify(props.customer))

      const { data } = await createCheckout({
        salesOrganization: props.salesOrganization,
        customerCode: getCustomerCode,
        documents: props.selected,
        successUrl: `${process.env.REACT_APP_INVOICES_UI}/app/quotations`,
        errorUrl: `${process.env.REACT_APP_INVOICES_UI}/app/quotations`
      })

      const dataTcsInfo = {
        name_user: user.fullName,
        email_user: user.email,
        login_user: user.login,
        customers: user.customers,
        portal_account_id: user.accountCode,
        profile: user.profile
      }
      const convertTcsBase64 = btoa(JSON.stringify(dataTcsInfo))
      window.location.href = data.url + '?inv=' + convertTcsBase64

      // window.location.href = data.url
    } catch (err) {
      localStorage.clear()
      const error = err.response.data ? err.response.data.message : err
      Swal.fire('Error!', error, 'error')
    }
  }

  useEffect(() => {
    const statusPerfil =
      user.profile.includes('OLP_CUSTOMER') ||
      user.profile.includes('OLP_SUPPORT')

    statusPerfil
      ? setDisableStatus(disableButton(props))
      : setDisableStatus(true)
  }, [props])

  return (
    <Button
      variant='contained'
      color='primary'
      className={`${props.classes.button} i-button-primary`}
      disabled={disableStatus}
      onClick={handlePayNow}>
      {t('pay-now')}
    </Button>
  )
}
