import React from 'react'
import Check from '@material-ui/icons/Check'
import AccessTime from '@material-ui/icons/AccessTime'

export default (row) =>
  ['5'].includes(row.statusCode) ? (
    <Check fontSize='large' style={{ color: '#40a766' }} />
  ) : ['6'].includes(row.statusCode) ? (
    <AccessTime fontSize='large' style={{ color: '#ffa000' }} />
  ) : null
