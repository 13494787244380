const findItem = (selected) => (name) =>
  selected.find((item) => item.name === name)

const filterSelected = (selected) => (name) =>
  selected.filter((item) => item.name !== name)

export default (selected = []) =>
  (id) => {
    const itemSelected = findItem(selected)(id.name)
    return !itemSelected ? selected : filterSelected(selected)(id.name)
  }
