import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import { get, first } from 'lodash'
import numeral from 'numeral'

import { fetchQuotations } from '../quotation-list/__redux__/actions'
import QuotationList from '../quotation-list'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '../../component/expansion-panel'
import TooltipInvoices from '../../component/tooltip-invoices'
import NoData from '../../component/no-data'

const getCompaniesRedux = (state) =>
  get(state, 'getCompaniesQuotations.companies', [])
const getQuotations = (state) =>
  get(state, 'getQuotations.quotations', new Map())
const getCriteria = (state) => get(state, 'getFiltersQuotations.criteria')

export default (props) => {
  const { t } = useTranslation()
  const reduxCompanies = useSelector(getCompaniesRedux)
  const quotations = useSelector(getQuotations)
  const criteria = useSelector(getCriteria)
  const dispatch = useDispatch()

  const [rowCompanies, setRowCompanies] = useState([])
  const fetchQuot = (company) =>
    fetchQuotations({
      salesOrganization: company.salesOrganization,
      currency: company.currency,
      selects: props.selects,
      status: props.status,
      criteria
    })

  const fetchIfExpanded = (company, expanded) => {
    const quot = quotations.get(
      `${company.salesOrganization}-${company.currency}`
    )
    const quotList = get(quot, 'quotations', [])
    if (expanded && quotList.length < 1) {
      dispatch(fetchQuot(company))
    }
  }

  const hasOnlyOneCompany = (companies) => {
    if (companies.length === 1) {
      dispatch(fetchQuot(first(companies)))
      return true
    }
  }

  const reloadDoc = (company) => {
    const { salesOrganization, currency } = company
    if (quotations.size > 0) {
      if (quotations.has(`${salesOrganization}-${currency}`)) {
        dispatch(fetchQuot(company))
      }
    }
  }

  useEffect(() => {
    setRowCompanies(reduxCompanies)
  }, [reduxCompanies])

  useEffect(() => {
    setRowCompanies([])
  }, [props.status])

  const QuotationListMemo = useMemo(() => {
    return (
      <React.Fragment>
        {rowCompanies.map((company) => (
          <ExpansionPanel
            key={`${props.status}-${company.id}`}
            expanded={hasOnlyOneCompany(reduxCompanies)}
            onChange={(newExpanded) => fetchIfExpanded(company, newExpanded)}>
            {reloadDoc(company)}
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-header-${props.status}-${company.id}`}
              id={`panel1a-header-${props.status}-${company.id}`}>
              <div className='i-collapsible-top'>
                <div>
                  <span
                    className='i-collapsible-ico'
                    data-flag={company.country}></span>
                  <span>{company.name}</span>
                </div>
                <div className='i-collapsible-currency-area'>
                  <span className='i-collapsible-label'>
                    {company.currency}
                  </span>
                </div>
                <div>
                  <span className='i-states-invoices' data-label={t('overdue')}>
                    <i className='material-icons i-ico-overdue-color'>
                      error_outline
                    </i>
                  </span>
                  <span className='i-collapsible-label'>
                    {numeral(company.overdue).format('0.00a')}
                  </span>
                </div>

                <div>
                  <span className='i-states-invoices' data-label={t('credit')}>
                    <i className='material-icons i-ico-credit-color'>
                      attach_money
                    </i>
                  </span>
                  <span className='i-collapsible-label'>
                    {numeral(company.credit).format('0.00a')}
                  </span>
                </div>
                <div>
                  <span
                    className='i-states-invoices'
                    data-label={t('notOverdue')}>
                    <i className='material-icons i-ico-not-overdue-color'>
                      event_available
                    </i>
                  </span>
                  <span className='i-collapsible-label'>
                    {numeral(company.notOverdue).format('0.00a')}
                  </span>
                </div>

                <div className='tooltip-summary'>
                  <span>
                    <TooltipInvoices />
                  </span>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <QuotationList
                salesOrganization={company.salesOrganization}
                currency={company.currency}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </React.Fragment>
    )
  }, [rowCompanies, props])

  return rowCompanies.length ? QuotationListMemo : <NoData />
}
