import React, { useEffect, useState } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import IsEmptyChart from '../../charts/amcharts-is-empty'

import { config } from './config'

import AmChartsSerial from '../../charts/amcharts-serial'
import { findCustomerCreditLimit } from '../../domain/user'

const getCustomerCreditLimit = (state) =>
  get(state, 'getCustomerCreditLimit.customerDocuments', [])

export default (props) => {
  const reduxDataProvider = useSelector(getCustomerCreditLimit)
  const [configGraph, setConfigGraph] = useState(config)
  const [stateDataProvider, setStateDataProvider] = useState(reduxDataProvider)
  const [state, setState] = useState(false)
  const { t } = useTranslation()

  const loading = async () =>
    findCustomerCreditLimit().then((dataProvider) => {
      setStateDataProvider(dataProvider.data.body)
    })

  useEffect(() => {
    loading()
    const newConfigGraph = configGraph
    newConfigGraph.graphs[0].title = `${t('customer-credit-limit')} (Total)`
    newConfigGraph.graphs[0].labelText = t('customer-credit-limit-text')
    newConfigGraph.graphs[0].balloonText = t(
      'customer-credit-limit-balloon-text'
    )
    newConfigGraph.graphs[1].title = t('customer-used-limit')
    setConfigGraph(newConfigGraph)
    setState(true)
  }, [state])

  useEffect(() => {
    setStateDataProvider(reduxDataProvider)
  }, [reduxDataProvider])

  return !isEmpty(stateDataProvider) ? (
    <Card>
      <CardContent>
        <Typography
          gutterBottom
          style={{ fontSize: '1.9rem', color: '#666666' }}>
          {props.title}
        </Typography>

        <AmChartsSerial
          height={`${
            stateDataProvider.length < 5 ? 500 : stateDataProvider.length * 100
          }px`}
          dataConfig={configGraph}
          dataProvider={stateDataProvider}
          isCustomerDashboard={true}
        />
      </CardContent>
    </Card>
  ) : (
    <IsEmptyChart title={props.title} />
  )
}
