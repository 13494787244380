import { search, searchAll } from '../../../domain/quotation'

const GET_QUOTATIONS = 'GET_QUOTATIONS'
const GET_PAGINATION_QUOTATIONS = 'GET_PAGINATION_QUOTATIONS'
const LOAD_SHOW_MORE = 'LOAD_SHOW_MORE'

const getQuotations =
  ({ salesOrganization, currency }) =>
  (quotations) => ({
    type: GET_QUOTATIONS,
    row: {
      id: `${salesOrganization}-${currency}`,
      quotations: quotations.rows,
      page: parseInt(quotations.page, 10),
      pageSize: parseInt(quotations.pageSize, 10),
      count: quotations.count
    }
  })

const getPaginationQuotation =
  ({ salesOrganization, currency }) =>
  (quotations) => ({
    type: GET_PAGINATION_QUOTATIONS,
    row: {
      id: `${salesOrganization}-${currency}`,
      quotations: quotations.rows,
      page: parseInt(quotations.page, 10),
      pageSize: parseInt(quotations.pageSize, 10),
      count: quotations.count
    }
  })

const setLoadShowMore = (loadShowMore) => ({
  type: LOAD_SHOW_MORE,
  loadShowMore
})

const fetchQuotations = (query) => (dispatch) =>
  search(query).then((quotation) =>
    dispatch(getQuotations(query)(quotation.data))
  )

const fetchAllQuotations = (query) => (dispatch) =>
  searchAll(query).then((quotation) => {
    dispatch(getQuotations(query)(quotation.data))
    dispatch(setLoadShowMore(false))
  })

const fetchPaginationQuotations = (query) => (dispatch) =>
  search(query).then((quotation) => {
    dispatch(getPaginationQuotation(query)(quotation.data))
    dispatch(setLoadShowMore(false))
  })

export {
  GET_QUOTATIONS,
  GET_PAGINATION_QUOTATIONS,
  fetchPaginationQuotations,
  fetchQuotations,
  fetchAllQuotations,
  LOAD_SHOW_MORE,
  setLoadShowMore
}
