import React, { Fragment } from 'react'

import ExpansionPanelFooter from '../../../component/expansion-panel/expansion-panel-footer'
import TableToolbar from './table-toolbar'
import ShowTotalAndPaynow from './show-total-and-paynow'

const TableFooter = (props) => {
  return (
    <Fragment>
      <ExpansionPanelFooter>
        <TableToolbar {...props} />
        {props.payNowWarning ? (
          <div className='paynow-warning'>{props.payNowWarning}</div>
        ) : null}
        <ShowTotalAndPaynow
          {...props}
          inputSearch={props.inputSearch}
          leader={props.leader}
          camCsi={props.camCsi}
          customerGroup={props.customerGroup}
          customer={props.customer}
          csi={props.csi}
        />
      </ExpansionPanelFooter>
    </Fragment>
  )
}

export default TableFooter
