import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import {
  PictureAsPdf as PdfIcon,
  Image as ImageIcon
} from '@material-ui/icons/'
import Button from '../../../component/button'
import axios from 'axios'
import ReportProblem from '@material-ui/icons/ReportProblem'

import TableCell from '../../../component/table/table-cell'
import Loading from '../../../component/loading'
import Tooltip from '../../../component/tooltip'
import { setToastMessage } from '../../snackbar/__redux__/actions'
import { getVoucherFileFromBucket } from '../../../domain/document'
import { get } from 'lodash'
import { getBearerAuthorization } from '../../../domain/auth'
import getMimeType from '../../../helpers/get-mime-type'
import { useTranslation } from 'react-i18next'

const renderIcon = (row) =>
  row ? (
    <IconButton aria-label='pdf'>
      {row.voucher.split('.')[1].toLowerCase() === 'pdf' ? (
        <PdfIcon fontSize='large' style={{ color: '#f66166' }} />
      ) : (
        <ImageIcon fontSize='large' style={{ color: 'blue' }} />
      )}
    </IconButton>
  ) : (
    <Tooltip
      textTooltip='PDF is not available'
      color='#ffffff'
      position='flex'
      backgroundColor='rgba(0,0,0,0.75)'>
      <div>
        <IconButton aria-label='pdf' disabled>
          <PdfIcon fontSize='large' title='PDF Not Generated' />
        </IconButton>
      </div>
    </Tooltip>
  )

const getAuth = (state) => get(state, 'auth.user')

const WireTransferCell = ({
  row,
  inputSearch,
  leader,
  camCsi,
  customerGroup,
  customer,
  csi
}) => {
  const [stateDownloadPdf, setStateDownloadPdf] = useState(false)
  const [stateError, setStateError] = useState(false)
  const dispatch = useDispatch()
  const invoicesApi = process.env.REACT_APP_INVOICES_API
  const url = `${invoicesApi}/v1/documents/get-checkout-by-transaction-number`
  const { t } = useTranslation()

  const user = useSelector(getAuth)

  const forceDownload = async (doc, row) => {
    const mimeType = getMimeType(row)

    const blob = new Blob([new Uint8Array(doc.file.Body.data).buffer], {
      type: mimeType
    })

    const namePdf = `${row.customerCode}.${mimeType.split('/')[1]}`

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', namePdf)
    document.body.appendChild(link)
    link.click()
  }

  const downloadPdf = async (row) => {
    setStateError(false)
    setStateDownloadPdf(true)

    try {
      const {
        data: { body }
      } = await getVoucherFileFromBucket(row)
      await forceDownload(body, row)
    } catch (error) {
      setStateError(true)
      dispatch(
        setToastMessage({
          open: true,
          variant: 'error',
          message: 'An error occurred while getting PDF!'
        })
      )
    }
    setStateDownloadPdf(false)
  }

  const backToWireTransferCheckout = async (row) => {
    const response = await axios.get(`${url}/${row.transactionNumber}`, {
      headers: getBearerAuthorization()
    })

    const dataTcsInfo = {
      name_user: user.fullName,
      email_user: user.email,
      login_user: user.login,
      customers: user.customers,
      portal_account_id: user.accountCode,
      profile: user.profile
    }

    const convertTcsBase64 = btoa(JSON.stringify(dataTcsInfo))
    window.location.href = `${process.env.REACT_APP_EPAYMENTS_UI}/checkout/${response.data.checkout.id}?inv=${convertTcsBase64}`
  }

  return (
    <>
      {row.voucher && row.transactionNumber ? (
        <TableCell
          align='left'
          data-label-thead='View'
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            downloadPdf(row)
          }}>
          {stateDownloadPdf ? <Loading downloadBig /> : renderIcon(row)}
          {stateError ? (
            <Tooltip
              textTooltip='Document not found!'
              color='#ffffff'
              position='bottom-end'
              backgroundColor='rgba(0,0,0,0.75)'>
              <ReportProblem fontSize='large' style={{ color: '#f66166' }} />
            </Tooltip>
          ) : null}
        </TableCell>
      ) : row.transactionNumber &&
        row.transactionNumber.slice(0, 3) === 'Pay' ? (
        <TableCell
          align='left'
          data-label-thead='View'
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            localStorage.clear()
            localStorage.setItem('@inputSearch', inputSearch)
            localStorage.setItem('@leader', JSON.stringify(leader))
            localStorage.setItem('@camCsi', JSON.stringify(camCsi))
            localStorage.setItem('@csi', JSON.stringify(csi))
            localStorage.setItem(
              '@customerGroup',
              JSON.stringify(customerGroup)
            )
            localStorage.setItem('@customer', JSON.stringify(customer))

            backToWireTransferCheckout(row)
          }}>
          <Button style={{ cursor: 'pointer', backgroundColor: '#DFE0E1' }}>
            {t('wire-transfer-attach-button')}
          </Button>
        </TableCell>
      ) : (
        <TableCell />
      )}
    </>
  )
}

export default WireTransferCell
