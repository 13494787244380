import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import {
  fetchPaginationQuotations,
  fetchAllQuotations,
  setLoadShowMore
} from '../quotation-list/__redux__/actions'

import Loading from '../../component/loading'
import Table from '../table-quotation'

const getStatus = (state) => get(state, 'getFiltersQuotations', [])
const getAuth = (state) => get(state, 'auth.user')

const getQuotations =
  ({ salesOrganization, currency }) =>
  (state) =>
    get(state, 'getQuotations.quotations', new Map()).get(
      `${salesOrganization}-${currency}`
    )

const QuotationList = (props) => {
  const dispatch = useDispatch()
  const quotations = useSelector(getQuotations(props))
  const { status, criteria, selects } = useSelector(getStatus)
  const { salesOrganization, currency } = props
  const user = useSelector(getAuth)

  const [loadingStatus, setLoadingStatus] = useState(true)

  const setOnShowAll = useCallback(
    (salesOrganization, currency) => {
      dispatch(
        fetchAllQuotations({
          salesOrganization,
          currency,
          status,
          selects,
          criteria
        })
      )
      dispatch(setLoadShowMore(true))
    },
    [salesOrganization, currency]
  )

  const setOnShowMore = useCallback(
    (page, salesOrganization, currency) => {
      dispatch(
        fetchPaginationQuotations({
          salesOrganization,
          currency,
          status,
          selects,
          criteria,
          page
        })
      )
      dispatch(setLoadShowMore(true))
    },
    [salesOrganization, currency]
  )

  useEffect(() => {
    if (loadingStatus) {
      setTimeout(() => {
        setLoadingStatus(false)
      }, 1500)
    }
  }, [loadingStatus])

  const TableMemo = useMemo(() => {
    return (
      <Table
        {...quotations}
        salesOrganization={salesOrganization}
        currency={currency}
        showSort={true}
        customersSelected={selects}
        status={status}
        onShowMore={setOnShowMore}
        onShowAll={setOnShowAll}
        user={user}
      />
    )
  }, [quotations, user])

  return loadingStatus ? <Loading /> : TableMemo
}

export default QuotationList
