import React, { useState } from 'react'

import { MenuItem } from '@material-ui/core'
import { Email, MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import { sendNotification } from '../../domain/notifications'
import { ActionsButton, MenuContainer } from './styles'

import Swal from 'sweetalert2'

const NotificationsMenu = ({ account, handleOpen }) => {
  const { t } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState()
  const open = Boolean(isMenuOpen)

  const handleClick = async (event) => {
    setIsMenuOpen(event.currentTarget)
  }

  const handleClose = () => {
    setIsMenuOpen(null)
  }

  const handleSendNotification = async (notification) => {
    const r = await handleOpen()

    const companiesText = r.map((company) => company.name).join(', ')

    if(companiesText.length === 0) {
      Swal.fire(t('error'), 'Nenhuma compania selecionada')
    }
    const companieCode = r.map((company) => company.code)
    const companieEmail = r.map((company) => company.emails)
    
    if(companieCode.length > 1) {
      Swal.fire({
        title: t('alert-invoices-title'),
        text: t('user-send-notification'),
        icon: 'warning',
      })
      return
    }

    const emails = companieEmail[0].map((email) => email.email)

    Swal.fire({
      title: t('user-confirm-status-update'),
      text: `Enviar o ${notification} para as companias: ${companiesText}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('user-cancel-button'),
      confirmButtonText: t('user-update-status-confirm-button')
    }).then(async (result) => {
      if (result.value) {
        try {
          await sendNotification({
            emails,
            notification,
            code: companieCode[0]
          })

          Swal.fire(
            t('success'),
            `${notification} enviada para a compania ${companiesText}`,
            'success'
          )
        } catch (err) {
          let response = err.response.data.body
          let message = ''

          if (/(Summaries not found)/.test(response))
            message = t('user-notification-not-found-error')
          else if (/(No overdue values found)/.test(response))
            message = t('user-notification-not-found-error')
          else if (/(Document template not generated)/.test(response))
            message = t('user-notification-error')
          else message = t('user-notification-error')

          Swal.fire({
            icon: 'error',
            title: t('error'),
            text: message
          })
        }
      }
    })

    handleClose()
  }

  return (
    <>
      <ActionsButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVert />
      </ActionsButton>
      <MenuContainer
        id='basic-menu'
        anchorEl={isMenuOpen}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleSendNotification('Step 1')}>
          <Email className='action-icons' /> Step 1
        </MenuItem>
        <MenuItem onClick={() => handleSendNotification('Step 2')}>
          <Email className='action-icons' /> Step 2
        </MenuItem>
        <MenuItem onClick={() => handleSendNotification('Step 2.1')}>
          <Email className='action-icons' /> Step 2.1
        </MenuItem>
        <MenuItem onClick={() => handleSendNotification('Step 3')}>
          <Email className='action-icons' /> Step 3
        </MenuItem>
      </MenuContainer>
    </>
  )
}

export default NotificationsMenu
